import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Ari Vochard`}</strong>{` was a a professor at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`, father of `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{` and `}<a parentName="p" {...{
        "href": "/Vytas%20Quint",
        "title": "Vytas Quint"
      }}>{`Vytas Quint`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      